import request from '@/utils/edp/request.js'
// 获取文章列表
export const apiNewsList = (params) =>
  request({
    method: 'get',
    url: '/api/article',
    params
  })

// 获取人员列表
export const apiUserList = (params) =>
  request({
    method: 'get',
    url: '/api/teamList',
    params
  })

// 获取文章详情
export const apiArticle = (params) =>
  request({
    method: 'post',
    url: '/api/article/view',
    params
  })

// 获取视频列表
export const apiVideoList = (params) =>
  request({
    method: 'get',
    url: '/api/video',
    params
  })