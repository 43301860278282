import axios from 'axios'
// 创建axios实例
let example = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 6000
})

example.interceptors.request.use((config) => {
  return config
})
example.interceptors.response.use((result) => {
  let res
  let { data, status } = result
  switch (status) {
    case 200:
      res = data
      break
    case 9001:
      res = Promise.reject('token过期')
      break
  }
  return res
})

export default example
