<template>
  <div class="user">
    <content-title :title="data.content.title_en" />
    <div class="html-box" v-html="data.content.content" v-if="data.content.content" />
    <el-skeleton :rows="5" v-else />


    <div class="share">
      <div class="basics">
        <svg-icon name="share" />
      </div>
      <div class="children">
        <div class="wb" @click="handlerToWb">
          <svg-icon name="wb" />
        </div>
        <div class="wx">
          <div class="box">
            <svg-icon name="wx" />
          </div>

          <div class="qr">
            <img :src="data.content.wx_share_img" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { apiArticle } from '@/api/list'
import { onMounted, reactive } from 'vue'
import useParams from '@/hooks/getParams.js'
import contentTitle from '@/layout/component/contentTitle.vue'
import useShare from '@/hooks/useShare'

// 获取url中的参数
let params = useParams()

let data = reactive({
  content: {},
  params: {}
})
const getArticle = async () => {
  try {
    let result = await apiArticle({
      id: params.detailsId
    })
    result.data.content = result.data.content_en
    data.content = result.data
    useShare({
      title_en: data.content.title_en,
      description: data.content.description_en,
      thumb: data.content.thumb,
    })
  } catch (e) {
    console.error("🚀 ~ file: index.vue:42 ~ getArticle ~ e:", e)
  }
}

// 跳转到微博
const handlerToWb = () => {
  let href = location.href
  window.open(
    `https://service.weibo.com/share/share.php?url=${href}&title=${data.content.title_en}`,
    '_blank',
  )
}

onMounted(() => {
  getArticle()
})
</script>

<style lang="scss">
@media screen and (min-width: $media) {
  .user {
    position: relative;
    width: 100%;
    padding-bottom: 185px !important;

    .html-box {

      img {
        // width: 100% !important;
        height: auto;
        margin: 18px 0 0 0;
      }

      p {
        font-size: .1875rem;
        font-family:
          Source Han Sans CN-Medium,
          Source Han Sans CN;
        font-weight: 500;
        color: #5a5a5a;
        line-height: .3203rem;
        margin: 18px 0 0 0;
      }
    }

    .qr-code {
      margin-top: .2344rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: .28rem;
        line-height: .4rem;
      }
    }

    .share {
      position: absolute;
      right: -1.2578125rem;
      top: 2rem;
      width: .3906rem;
      height: .3906rem;
      background-color: #A6291A;
      z-index: 999;
      cursor: pointer;

      .basics {
        width: 100%;
        height: 100%;
        padding: .0781rem;
      }

      .children {
        opacity: 0;
        visibility: hidden;
        margin-top: .0391rem;
        width: .3906rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: all 0.3s;

        >div {
          width: .3906rem;
          height: .3906rem;
          background-color: #F0845C;
          margin-bottom: .0391rem;

        }

        .wb {
          width: 100%;
          padding: .0781rem;
        }

        .wx {
          position: relative;

          .box {
            width: .3906rem;
            height: .3906rem;
            padding: .0781rem;
          }

          .qr {
            visibility: hidden;
            opacity: 0;
            width: 1.5625rem;
            height: 1.5625rem;
            position: absolute;
            left: -1.6406rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            border: 1px solid #000;
            padding: .0781rem;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &:hover {
            .qr {
              opacity: 1;
              visibility: visible;
            }
          }
        }

      }

      &:hover {
        .children {
          opacity: 1;
          visibility: visible;
        }
      }
    }

  }
}

@media screen and (max-width: $media) {
  .user {
    width: 100%;
    margin-bottom: 50px !important;

    .html-box {
      font-size: 4vw !important;
      overflow: hidden;
      font-family: Source Han Sans CN-Bold !important;

      p {
        font-family: 微软雅黑;
        margin: 18px 0 0 0;
      }

      img {
        // width: 100% !important;
        max-width: 100%;
        max-height: 10rem;
        height: auto;
      }
    }

    div {
      font-size: 3.2vw;
      font-family:
        Source Han Sans CN-Medium,
        Source Han Sans CN;
      font-weight: 500;
      color: #5a5a5a;
      line-height: 5.4667vw;

      p {
        font-size: 3.2vw;
        font-family:
          Source Han Sans CN-Medium,
          Source Han Sans CN;
        font-weight: 500;
        color: #5a5a5a;
        line-height: 5.4667vw;
      }
    }

    .share {
      display: none;
      position: absolute;
      right: -1.2578125rem;
      top: 2rem;
      width: .625rem;
      height: .625rem;
      background-color: #A6291A;
      z-index: 999;
      cursor: pointer;

      .basics {
        width: 100%;
        height: 100%;
        padding: .0781rem;
      }

      .children {
        opacity: 0;
        margin-top: .0391rem;
        width: .625rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: all 0.3s;

        >div {
          width: .625rem;
          height: .625rem;
          background-color: #F0845C;
          margin-bottom: .0391rem;

        }

        .wb {
          width: 100%;
          padding: .0781rem;
        }

        .wx {
          position: relative;

          .box {
            width: .625rem;
            height: .625rem;
            padding: .0781rem;
          }

          .qr {
            opacity: 0;
            width: 1.5625rem;
            height: 1.5625rem;
            position: absolute;
            left: -1.6406rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;

            img {
              width: 1.5625rem;
              height: 1.5625rem;
            }
          }

          &:hover {
            .qr {
              opacity: 1;
            }
          }
        }

      }

      &:hover {
        .children {
          opacity: 1;
        }
      }
    }
  }
}
</style>
